<template>
  <div class="col-12 py-5 bg p-0">
    <div class="container-lg" >
      <div class="row mx-0 align-items-md-center">
        <div class="col-md-6 py-5" ref="text_height">
          <div class="row m-0 justify-content-center justify-content-md-start">
            <div class="col-12 gold_heading">
              DID YOU KNOW?
            </div>
            <div class="col-12 green_heading">
              You can increase<br/>your donation by<br/>25% for FREE
            </div>
            <div class="col-12 my-4 text">
              If you're an all eligible tax-payer in the UK, you can increase all your donations by 25% at no extre cost to you, making each £1.00 you donate worth £1.25 and every £100 you donate into £125.00!
            </div>
            <div class="col-auto">
              <Button color="green" btnText="Learn More" icon="arrow" @buttonClicked="goTo('giftaid')" class="mb-1" >
                <IconArrowForward color="gold" />
              </Button>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block" :style="{height: theHeight}">
          <GiftAidAnimation :height="theHeight" />
        </div>
        <div class="col-12 order-first d-md-none">
          <GiftAidAnimation :width="theWidth" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    GiftAidAnimation: defineAsyncComponent(() => import('@/components/animations/GiftAidAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Checkout GiftAid Section',
  data () {
    return {
      theHeight: '',
      theWidth: ''
    }
  },
  mounted () {
    if (window.innerWidth < 992) {
      this.matchWidth()
    } else {
      this.matchHeight()
    }
  },
  methods: {
    matchHeight () {
      var heightString = (this.$refs.text_height.clientHeight) + 'px'
      this.theHeight = heightString
    },
    matchWidth () {
      var widthString = (this.$refs.text_height.clientWidth) + 'px'
      this.theWidth = widthString
    },
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>

<style scoped>
.bg {
  background-color: rgb(244, 244, 244);
}
.gold_heading {
  color: var( --gold-color );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 6.4px;
  text-transform: uppercase;
  text-align: center;
}
.green_heading {
  color: var( --green-color );
  padding: 9px 0px 0px 0px;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
}
.text {
  font-size: 1rem;
  text-align: center;
}
@media (min-width: 992px) {
  .gold_heading {
    color: var( --gold-color );
    font-family: "quicksand_bold", Sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 6.4px;
    text-transform: uppercase;
    text-align: left;
  }
  .green_heading {
    color: var( --green-color );
    padding: 9px 0px 0px 0px;
    font-family: "quicksand_bold", Sans-serif;
    font-size: 3.25rem;
    font-weight: bold;
    line-height: 1.4;
    text-align: left;
  }
  .text {
    font-size: 1.3rem;
    text-align: left;
  }
}
</style>
